import Cookies from 'js-cookie'
import * as types from '@/redux/actionTypes'
import { updateCartModal } from './uiAction'
import { getProductInventory } from './productAction'
import { enableBodyScrollCart, disableBodyScroll, getProductReviewCount } from '@/lib/helper'
import { addToCart, removeCartItem, getCartData, updateCart } from '@/lib/api/cart'
import {
  getQuantityOfCartProduct,
  getProductByIdForCustomField,
  getProductById,
  getProductMetafields,
} from '@/lib/api/products'
import { addToCartEvent, getBadgeData } from '@/lib/ga-events'

// Tracking
import { trackEvent } from '@/lib/tracking'
import { getBrand } from '@/lib/product-helper'
import { isJsonString } from '@/lib/helpers/strings'

export const calculatedTotalDiscount = (items) => {
  let totalDiscountAmount =
    items?.length > 0 ? items?.reduce((total, item) => total + item.discount_amount, 0) : 0
  return totalDiscountAmount
}

export const addToCartItem = async (lineItem, dispatch, customerId, errorCallback) => {
  disableBodyScroll()
  dispatch({ type: types.ADD_CART_REQUEST })
  try {
    // for multiple product selection in system builder  i need to pass lineItem as array format so added below params conditionally
    // but it won't break your old add to cart functionality for single product. so please keep this .

    const { data, errorData } = await addToCart(
      {
        line_items: Array.isArray(lineItem) ? lineItem : lineItem?.quantity ? [lineItem] : [],
        gift_certificates: lineItem.gift_certificates ? lineItem?.gift_certificates : [],
      },
      customerId
    )

    if (errorCallback && errorData) {
      errorCallback(errorData)
      enableBodyScrollCart()
      return
    }

    data &&
      dispatch({
        type: types.ADD_CART_SUCCESS,
        payload: {
          ...data,
          discount_amount_total: calculatedTotalDiscount(data.line_items.physical_items),
          all_cart_items: data?.line_items?.physical_items?.map((item) => item) || [], //({ ...item, added_to_wishlist: false}))
          all_gift_items: data?.line_items?.gift_certificates?.map((item) => item) || [],
        },
      })
    data && !lineItem.gift_certificates && addToCartEvent(lineItem, data)
    await dispatch(updateCartModal(true))
    await getCart(dispatch)
    // enableBodyScroll()
    // console.log('data', data)
    if (Array.isArray(lineItem)) {
      await Promise.all(
        lineItem.map((product) => {
          return trackEvent({
            event: 'Added to Cart',
            lineItem: product,
            customerId: customerId,
          })
        })
      )
    } else {
      await trackEvent({
        event: 'Added to Cart',
        lineItem,
        customerId: customerId,
      })
    }
    return data
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.ADD_CART_FAILURE })
    Cookies.remove('cart')
    addToCartItem(lineItem, dispatch, customerId)
    console.error('addToCart err', err)
    throw err
  }
}

export const addToCartItems = async (lineItems, dispatch, customerId) => {
  disableBodyScroll()
  dispatch({ type: types.ADD_CART_REQUEST })

  try {
    const { data } = await addToCart({ line_items: lineItems }, customerId)

    // console.log("data", data);
    data &&
      dispatch({
        type: types.ADD_CART_SUCCESS,
        payload: {
          ...data,
          discount_amount_total: calculatedTotalDiscount(data.line_items.physical_items),
          all_cart_items: data.line_items.physical_items.map((item) => item) || [],
          all_gift_items: data?.line_items?.gift_certificates?.map((item) => item) || [],
        },
      })

    data & addToCartEvent(lineItems, data)

    await dispatch(updateCartModal(true))
    await getCart(dispatch)
    // enableBodyScroll()
    // console.log('data', data)
    return data
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.ADD_CART_FAILURE })
    console.error('addToCart err', err)
    throw err
  }
}

export const addStdProductToCart = async (
  customer,
  modifiers,
  product_id,
  quantity,
  variant_id,
  originalPrice,
  salePrice,
  cartData,
  dispatch,
  variant = null
) => {
  const addModifier = await addModifiers(
    product_id,
    variant_id,
    quantity,
    originalPrice,
    salePrice,
    modifiers,
    cartData,
    false,
    variant
  )
  const data = await addToCartItem(
    addModifier,
    dispatch,
    (customer && customer?.profile && customer?.profile?.id) || null
  )
  return data
}

export const addProProductToCart = async (
  customer,
  modifiers,
  product_id,
  quantity,
  variant_id,
  originalPrice,
  salePRice,
  cartData,
  dispatch,
  variant
) => {
  const addModifier = await addModifiers(
    product_id,
    variant_id,
    quantity,
    originalPrice,
    salePRice,
    modifiers,
    cartData,
    true,
    variant
  )
  addToCartItem(
    addModifier,
    dispatch,
    (customer && customer?.profile && customer?.profile?.id) || null
  )
}

export const checkProInventoryOfProduct = async (
  variant,
  all_cart_items,
  quantity,
  customer,
  modifiers,
  originalPrice,
  salePrice,
  cartData,
  dispatch
) => {
  const cachedInventory = await dispatch(getProductInventory())

  const productInventory = await getQuantityOfCartProduct(
    variant?.sku,
    variant?.product_id == cachedInventory?.productId ? cachedInventory : null
  )

  const cartProductQuantity = all_cart_items.find(
    (item) => item?.sku === variant?.sku && item?.options?.find((el) => el.value === '03')
  )?.quantity

  if (productInventory <= cartProductQuantity || productInventory <= 0) {
    if (variant) {
      const modifierAdd = await addModifiers(
        variant?.product_id,
        variant?.id,
        quantity,
        variant?.price || variant?.calculated_price,
        variant?.sale_price,
        modifiers,
        cartData,
        false,
        variant
      )

      addToCartItem(
        modifierAdd,
        dispatch,
        (customer && customer?.profile && customer?.profile?.id) || null
      )
    }
  } else {
    await addProProductToCart(
      customer,
      modifiers,
      variant?.product_id,
      quantity,
      variant?.id,
      originalPrice,
      variant?.sale_price,
      cartData,
      dispatch,
      variant
    )
  }
}

export const addModifiers = async (
  productId,
  variantId,
  quantity,
  originalPrice,
  salePrice,
  modifiers,
  cartData,
  isProUser,
  variant
) => {
  //fetch product custom option for product_type
  const custom_opt = await getProductByIdForCustomField(productId)
  const productType =
    custom_opt && custom_opt?.custom_fields?.find((item) => item.name == 'product_type')
  const productExclude =
    custom_opt && custom_opt?.custom_fields?.find((item) => item.name == 'exclude_pro_discount')
  let isProductWader = false
  if (productType || productExclude) {
    if (
      productType?.value?.toLowerCase() == 'wader' ||
      productExclude?.value?.toLowerCase() === 'true'
    )
      isProductWader = true
  }

  //check if the product have sale price or not
  let checkIsSale
  let listPrice
  if (isProUser) {
    checkIsSale =
      variant?.sale_price && variant?.sale_price !== 0
        ? +variant?.sale_price < +variant?.price
        : +variant?.price > +originalPrice
    listPrice =
      +variant?.calculated_price > +originalPrice ? originalPrice : variant?.calculated_price
  } else {
    checkIsSale = salePrice && +salePrice !== 0 ? +salePrice < +originalPrice : false
    listPrice = checkIsSale ? +salePrice : +originalPrice
  }

  // if product type is Wader, then set sale modifier
  // if (isProductWader) checkIsSale = true
  // for sale modifiers
  let option_value_sale = null
  let option_id_sale = null

  // modifiers for Sale
  modifiers?.find((obj) => {
    if (obj.display_name?.toLowerCase() === 'sale') {
      if (checkIsSale) {
        obj?.option_values?.find((opt) => {
          if (opt?.label === 'Yes') {
            option_id_sale = opt?.option_id
            option_value_sale = opt?.id
          }
        })
      } else {
        obj?.option_values?.find((opt) => {
          if (opt?.label === 'No') {
            option_id_sale = opt?.option_id
            option_value_sale = opt?.id
          }
        })
      }
    }
  })

  // modifiers for cart_data
  let shippingGroup = cartData
  let cartOptId = null
  let cartOptValue = null
  modifiers?.find((obj) => {
    if (obj.display_name?.toLowerCase() === 'cart data') {
      cartOptId = obj.id
      cartOptValue = JSON.stringify(shippingGroup)
    }
  })

  // for warehouse modifiers
  let option_value = null
  let option_id = null

  // modifiers for Warehouse
  modifiers?.find((obj) => {
    if (obj.display_name?.toLowerCase() === 'warehouse') {
      obj?.option_values?.map((opt) => {
        if (isProUser && opt?.label === '03') {
          option_id = opt?.option_id
          option_value = opt?.id
          return
        } else if (opt?.label === '02') {
          option_id = opt?.option_id
          option_value = opt?.id
          return
        }
      })
    }
  })

  let colorCodeValue = variant?.sku ? variant?.sku?.split('-')?.[1] : null
  let colorCodeId = null

  if (colorCodeValue) {
    modifiers?.find((obj) => {
      if (obj.display_name?.toLowerCase() === 'color code') {
        colorCodeId = obj?.id
      }
    })
  }

  let optionSection = []

  if (option_id) {
    optionSection = [
      ...optionSection,
      {
        option_id: option_id,
        option_value: option_value,
      },
    ]
  }

  if (option_id_sale) {
    optionSection = [
      ...optionSection,
      {
        option_id: option_id_sale,
        option_value: option_value_sale,
      },
    ]
  }

  if (cartOptId) {
    optionSection = [
      ...optionSection,
      {
        option_id: cartOptId,
        option_value: cartOptValue,
      },
    ]
  }
  if (colorCodeId) {
    optionSection = [
      ...optionSection,
      {
        option_id: colorCodeId,
        option_value: colorCodeValue,
      },
    ]
  }

  let payload = {
    product_id: productId,
    quantity: quantity,
    variant_id: variantId,
    list_price: +listPrice,
    option_selections: optionSection,
  }
  return payload
}

export const getCart = async (dispatch, customer) => {
  dispatch({ type: types.LOAD_CART_REQUEST })

  try {
    const { data } = await getCartData()
    if (data && data.line_items) {
      const lineItems = data?.line_items?.physical_items || []
      if (lineItems.length > 0) {
        for (let i = 0; i < lineItems.length; i++) {
          let cartItemOptions = lineItems[i].options.find(
            (item) => item.name.toLowerCase() == 'cart data'
          )
          if (!cartItemOptions) {
            const itemId = lineItems[i]?.id
            let productId = lineItems[i]?.product_id
            const { data } = await getProductById(productId)
            const { data: metafields } = await getProductMetafields(productId)
            const variantInCart = data.variants.find(
              (variant) => variant.sku == lineItems[i].variant.sku
            )
            let cartData = {
              ga_data: '',
              shippingNotAllowed: false,
              utm_param: '',
            }
            let badgeData = await getBadgeData(data, lineItems[i].variant.sku)
            let gender = data.custom_fields.find(
              (field) => field.name.toLowerCase() == 'gender'
            )?.value
            let product_type = data.custom_fields.find(
              (field) => field.name.toLowerCase() == 'product_type'
            )?.value
            let brand_name = getBrand(data.brand_id)
            let variant_color = lineItems[i].options.find(
              (item) => item.name.toLowerCase() == 'color'
            )?.value
            let variant_size = lineItems[i].options.find(
              (item) => item.name.toLowerCase() == 'size'
            )?.value
            let product_review = getProductReviewCount(data.sku)
            let gaCartItem = {
              item_name: data.name,
              item_id: data.sku,
              affiliation: '',
              quantity: 1,
              price: '',
              item_category: gender,
              item_category2: product_type,
              item_brand: brand_name,
              item_variant: lineItems[i].variant.sku,
              item_list_name: '',
              item_list_id: '',
              item_regular_price: lineItems[i].variant.price,
              item_color: variant_color,
              item_size: variant_size,
              item_upc: variantInCart?.upc || '',
              item_rating: product_review?.score || 0,
              item_review_count: product_review?.total_count || 0,
              coupon: '',
            }
            if (Object.keys(badgeData)?.length > 0) {
              gaCartItem = { ...gaCartItem, ...badgeData }
            }
            cartData.ga_data = gaCartItem
            let utmParam =
              window.localStorage.getItem('utmParams') != '' &&
              window.localStorage.getItem('utmParams') != 'undefined'
                ? window.localStorage.getItem('utmParams')
                : 'undefined'
            cartData.utm_param = utmParam

            if (metafields && metafields.length > 0) {
              let shippingGroup = metafields.find(
                (group) => group.key.toLowerCase() == 'shipping-groups'
              )
              if (shippingGroup && isJsonString(shippingGroup.value)) {
                let groups = JSON.parse(shippingGroup.value)
                if (groups && groups.length > 0 && groups.includes('PFAS NOT ALLOWED')) {
                  cartData.shippingNotAllowed = true
                }
              }
            }

            if (cartData) {
              let cartDataModifier = data.modifiers.find(
                (option) => option.display_name.toLowerCase() == 'cart data'
              )
              if (cartDataModifier) {
                let modifierOption = [
                  {
                    option_id: cartDataModifier.id,
                    option_value: JSON.stringify(cartData),
                  },
                ]
                let colorCodeModifier = lineItems[i].options.find(
                  (option) => option.name.toLowerCase() == 'color code'
                )
                if (colorCodeModifier) {
                  modifierOption.push({
                    option_id: colorCodeModifier.nameId,
                    option_value: colorCodeModifier.value,
                  })
                }
                let warehouseModifier = lineItems[i].options.find(
                  (option) => option.name.toLowerCase() == 'warehouse'
                )
                if (warehouseModifier) {
                  modifierOption.push({
                    option_id: warehouseModifier.nameId,
                    option_value: warehouseModifier.valueId,
                  })
                }
                let saleModifier = lineItems[i].options.find(
                  (option) => option.name.toLowerCase() == 'sale'
                )
                if (saleModifier) {
                  modifierOption.push({
                    option_id: saleModifier.nameId,
                    option_value: saleModifier.valueId,
                  })
                }

                let itemToAdd = {
                  line_items: [
                    {
                      list_price: lineItems[i].list_price,
                      option_selections: modifierOption,
                      product_id: productId,
                      quantity: lineItems[i].quantity,
                      variant_id: variantInCart.id,
                    },
                  ],
                  gift_certificates: [],
                }
                await removeCartItem(String(itemId))
                await addToCart(itemToAdd, customer?.profile?.id)
              }
            }
          }
        }
      }
    }

    if (data && data.line_items) {
      dispatch({
        type: types.ADD_CART_SUCCESS,
        payload: {
          ...data,
          discount_amount_total: calculatedTotalDiscount(data.line_items.physical_items),
          all_cart_items: data.line_items.physical_items.map((item) => item), //({ ...item, added_to_wishlist: false}))
          all_gift_items: data?.line_items?.gift_certificates?.map((item) => item) || [],
        },
      })
    }

    // await dispatch(updateCartModal(true));
    // enableBodyScroll();

    // console.log('data', data)
    return data
  } catch (error) {
    dispatch({ type: types.ADD_CART_FAILURE })
    console.error('get cart error', error)
    throw error
  }
}

export const deleteCartItem = async (itemId, dispatch) => {
  dispatch({ type: types.DELETE_CART_REQUEST })

  try {
    const { data } = await removeCartItem(String(itemId))

    if (data && data.id) {
      dispatch({
        type: types.DELETE_CART_SUCCESS,
        payload: {
          ...data,
          discount_amount_total: calculatedTotalDiscount(data.line_items.physical_items),
          all_cart_items: data.line_items.physical_items.map((item) => item),
          all_gift_items: data?.line_items?.gift_certificates?.map((item) => item) || [],
        },
      })
    } else {
      dispatch({
        type: types.DELETE_CART_SUCCESS,
        payload: {
          base_amount: 0,
          cart_amount: 0,
          channel_id: 0,
          total_item: 0,
          coupons: [],
          created_time: '',
          currency: {},
          customer_id: 0,
          discount_amount: 0,
          email: '',
          id: '',
          line_items: {
            custom_items: [],
            digital_items: [],
            gift_certificates: [],
            physical_items: [],
          },
          locale: 'en',
          tax_included: false,
          updated_time: '',
          discount_amount_total: 0,
          all_cart_items: [],
          all_gift_items: [],
        },
      })
    }

    return data
  } catch (error) {
    dispatch({ type: types.DELETE_CART_FAILURE })
    console.error('deleteCartItem error', error)
    throw error
  }
}

export const updateCartItem = async (product, quantity, dispatch, cart, customerId, signal) => {
  dispatch({ type: types.UPDATE_CART_REQUEST })
  try {
    const response = await updateCart(product, quantity, customerId, signal)
    if (response.data && response.data.id) {
      dispatch({
        type: types.UPDATE_CART_SUCCESS,
        payload: {
          ...response.data,
          discount_amount_total: calculatedTotalDiscount(response.data.line_items.physical_items),
          all_cart_items: response.data.line_items.physical_items.map((item) => item),
          all_gift_items: response.data?.line_items?.gift_certificates?.map((item) => item) || [],
        },
      })
    } else {
      cart &&
        dispatch({
          type: types.UPDATE_CART_SUCCESS,
          payload: {
            ...cart,
            // updated: !cart.updated
          },
        })
    }

    return response
  } catch (error) {
    dispatch({ type: types.UPDATE_CART_FAILURE })
    // console.error('updateCartItem error', error)
    throw error
  }
}

export const updateCartItemOptions = async (cart, variantId, options, dispatch) => {
  getCart(dispatch)
}

export const clearCartItem = () => async (dispatch, getState) => {
  dispatch({ type: types.CLEAR_CART_REQUEST })

  try {
    return await dispatch({ type: types.CLEAR_CART_SUCCESS })
  } catch (err) {
    return dispatch({ type: types.CLEAR_CART_FAILURE })
  }
}

// export const getAbandonedCart = (t) => async (dispatch) => {
//   dispatch({ type: types.LOAD_CART_REQUEST })
//   try {
//     const { data } = await CartClient.post(`/abandoned/cart`, {
//       data: { token: t },
//     })
//     dispatch({
//       type: types.LOAD_CART_SUCCESS,
//       payload: data,
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.LOAD_CART_FAILURE })
//     console.log('getCart err', err)
//     throw err
//   }
// }

// export const updateItemQty = (itemId, quantity) => async (dispatch) => {
//   dispatch({ type: types.UPDATE_ITEM_QTY_REQUEST })
//   try {
//     dispatch({
//       type: types.UPDATE_ITEM_QTY_SUCCESS,
//       payload: { itemId, quantity },
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.UPDATE_ITEM_QTY_FAILURE })
//     console.log('updateItemQty err', err)
//   }
// }

// export const updateToCart = (item) => async (dispatch, getState) => {
//   let { cartReducer } = getState()
//   dispatch({ type: types.UPDATE_CART_REQUEST })
//   try {
//     const { data } = await CartClient.put(`/cart`, item)

//     let allCartItems = [...cartReducer.all_cart_items]

//     let updatedAllCartItems = allCartItems.map(cartItem => {

//       let updatedItem = data.line_items.physical_items.find(item => item.id == cartItem.id)

//       if (updatedItem) {
//         return {
//           ...updatedItem,
//           added_to_wishlist: cartItem.added_to_wishlist
//         }
//       }

//       return cartItem
//     })

//     dispatch({
//       type: types.UPDATE_CART_SUCCESS,
//       payload: {
//         ...data,
//         all_cart_items: updatedAllCartItems
//       },
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.UPDATE_CART_FAILURE })
//     console.log('updateToCart err', err)
//     throw err
//   }
// }

// export const deleteToCart = (item) => async (dispatch,getState) => {
//   let { cartReducer } = getState()
//   dispatch({ type: types.DELETE_CART_REQUEST })
//   try {
//     const { data } = await CartClient.delete(`/cart`, { data: { itemId: item.id } })

//     const newAllCartItems = cartReducer.all_cart_items.filter(cartItem => {
//       if (cartItem.id != item.id) {
//         return true
//       }

//       if (cartItem.added_to_wishlist == true) {
//         return true
//       }
//     })

//     dispatch({
//       type: types.DELETE_CART_SUCCESS,
//       payload: {
//         data,
//         newAllCartItems
//       },
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.DELETE_CART_SUCCESS })
//     console.log('deleteToCart err', err)
//     throw err
//   }
// }

// export const applyProDiscount = () => async (dispatch) => {
//   dispatch({ type: types.APPLY_CART_PRO_REQUEST })
//   try {
//     const { data } = await CartClient.put(`/cart/applyProDiscount`, {})
//     dispatch({
//       type: types.APPLY_CART_PRO_SUCCESS,
//       payload: data,
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.APPLY_CART_PRO_SUCCESS })
//     console.log('applyProDiscount err', err)
//   }
// }

// export const removeProDiscount = () => async (dispatch) => {
//   dispatch({ type: types.REMOVE_CART_PRO_REQUEST })
//   try {
//     const { data } = await CartClient.put(`/cart/removeProDiscount`, {})
//     dispatch({
//       type: types.REMOVE_CART_PRO_SUCCESS,
//       payload: data,
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.REMOVE_CART_PRO_SUCCESS })
//     console.log('applyProDiscount err', err)
//   }
// }

// export const updateAllCartItems = newAllCartItems => async (dispatch) => {

//   dispatch({type: types.UPDATE_ALL_CART_ITEMS_REQUEST})

//   try {
//     dispatch({
//       type: types.UPDATE_ALL_CART_ITEMS_SUCCESS,
//       payload: newAllCartItems,
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.UPDATE_ALL_CART_ITEMS_FAILURE })
//     console.log('updateAllCartItems err', err)
//   }
// }

// export const getCartCheckoutRedirectUrl = async () => {
//   try {
//     const data = await CartClient.get(`/checkout-url`)
//     Cookies.remove('redirect_checkout')
//     return data
//   } catch (err) {
//     // Handle Error Here
//     console.log('getCartCheckoutRedirectUrl err', err)
//     throw err
//   }
// }
