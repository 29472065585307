import { getProductById, getProductVariant, getProductsBySkus } from '@/lib/api/products'
import { getCartMetafields, createCartMetafield, updateCartMetafield } from './api/cart'
import { getBrand, getTechBadgeValue, getBadgeValue } from './product-helper'
import { getProductReviewCount, sendPixelData } from './helper'
import { server } from '@/config/server'
import Cookies from 'js-cookie'

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getBadgeData = async (product, variantSku) => {
  let badgesData = {}

  try {
    let techBadge = getTechBadgeValue(product)
    let badge = await getBadgeValue(product, variantSku)

    if (techBadge) {
      badgesData['item_technology_badge'] = techBadge
    }

    if (badge) {
      badgesData['item_product_badge'] = badge
    }

    return badgesData
  } catch (error) {
    console.log('addBadgeData', error)
    return badgesData
  }
}

export const addToCartEvent = async (lineItem, cartData, quantity) => {
  const lineItems = Array.isArray(lineItem) ? lineItem : [lineItem]
  const items = []
  await Promise.all(
    lineItems.map(async (item) => {
      const product = await getProductById(item?.product_id)
      const brandName = getBrand(product.brand_id)
      const variant = await getProductVariant(item?.product_id, item?.variant_id)
      const badgeData = await getBadgeData(product?.data, variant?.data?.sku)
      const itemListName = getPreviousListItemName(product?.data?.name)

      let productEventData = {
        item_name: product?.data?.name,
        item_id: product?.data?.sku,
        affiliation: '',
        quantity: quantity || item?.quantity,
        price: variant?.data?.calculated_price,
        item_category: product?.data?.custom_fields?.find((field) => field?.name == 'gender')
          ?.value,
        item_category2: product?.data?.custom_fields?.find((field) => field?.name == 'product_type')
          ?.value,
        item_brand: brandName,
        item_variant: variant?.data?.sku,
        item_list_name: itemListName,
        item_list_id: '',
        item_regular_price: variant?.data?.price,
        item_color: variant?.data?.option_values?.find(
          (option) => option?.option_display_name == 'Color'
        )
          ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Color')
              ?.label
          : '',
        item_size: variant?.data?.option_values?.find(
          (option) => option?.option_display_name == 'Color'
        )
          ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Size')
              ?.label
          : '',
        item_upc: variant?.data?.upc,
      }

      if (Object.keys(badgeData)?.length > 0) {
        productEventData = { ...productEventData, ...badgeData }
      }

      items.push(productEventData)
    })
  )

  console.debug('add cart event', items)
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: 'add_to_cart',
      ecommerce: { currency: cartData?.currency?.code, items: items },
    })
  }
}

export const removeFromCartEvent = async (product, cartData, quantity) => {
  const items = []

  const productData = await getProductById(product?.product_id)
  const brandName = getBrand(product.brand_id)

  let variant = null
  if (product?.variant?.id) {
    variant = await getProductVariant(product?.product_id, product?.variant?.id)
  }

  const badgeData = await getBadgeData(productData?.data, variant?.data?.sku)

  let productEventData = {
    item_name: product?.name,
    item_id: productData?.data?.sku,
    affiliation: '',
    quantity: quantity || product?.quantity,
    price: variant?.data?.calculated_price,
    item_category: productData?.data?.custom_fields?.find((field) => field?.name == 'gender')
      ?.value,
    item_category2: productData?.data?.custom_fields?.find((field) => field?.name == 'product_type')
      ?.value,
    item_brand: brandName,
    item_variant: variant?.data ? variant?.data?.sku : null,
    item_list_name: '',
    item_list_id: '',
    item_regular_price: variant?.data?.price,
    item_color: variant?.data
      ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Color')
        ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Color')
            ?.label
        : ''
      : null,
    item_size: variant?.data
      ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Color')
        ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Size')
            ?.label
        : ''
      : null,
    item_upc: variant?.data ? variant?.data?.upc : null,
  }

  if (Object.keys(badgeData)?.length > 0) {
    productEventData = { ...productEventData, ...badgeData }
  }

  items.push(productEventData)

  console.debug('remove cart event', items)

  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: { currency: cartData?.currency?.code, items: items },
    })
  }
}

function normalizeUrl(url) {
  const parsedUrl = new URL(url)
  parsedUrl.pathname = parsedUrl.pathname.replace(/\/$/, '')
  return parsedUrl.toString()
}

export const productViewEvent = async (product, color, size, variant) => {
  const brandName = getBrand(product.brand_id)
  const items = []

  if (product) {
    const badgeData = await getBadgeData(product, variant?.sku)

    let productEventData = {
      item_name: product?.name,
      item_id: product?.sku,
      affiliation: '',
      quantity: 1,
      index: 1,
      price: variant?.calculated_price,
      item_category: product?.custom_fields?.find((field) => field?.name == 'gender')?.value,
      item_category2: product?.custom_fields?.find((field) => field?.name == 'product_type')?.value,
      item_brand: brandName,
      item_variant: variant?.sku,
      item_list_name: '',
      item_list_id: '',
      item_regular_price: variant?.price,
      item_color: color,
      item_size: size,
      item_upc: variant?.upc,
    }

    if (Object.keys(badgeData)?.length > 0) {
      productEventData = { ...productEventData, ...badgeData }
    }

    items.push(productEventData)
  }
  // await timeout(3000)
  if (typeof window !== 'undefined' && window.dataLayer) {
    let lastItemSku = ''
    const lastViewEvent = dataLayer.filter((item) => item.event == 'view_item')
    const pageViewEvent = dataLayer.filter((item) => item.event == 'page_view')
    const virtualPageView = dataLayer.filter((item) => item.event == 'virtual_page_view')
    const url = new URL(window.location.href)
    const path = url.pathname
    let current_url = normalizeUrl(process.env.NEXT_PUBLIC_BASE_URL + path)
    let pageViewExist = false

    const lastPageView = pageViewEvent[pageViewEvent.length - 1]
    if (lastPageView && normalizeUrl(lastPageView.page_location) == current_url) {
      pageViewExist = true
    }
    const lastVirtualPageView = virtualPageView[virtualPageView.length - 1]
    if (lastVirtualPageView && normalizeUrl(lastVirtualPageView.page_location) == current_url) {
      pageViewExist = true
    }
    if (lastViewEvent.length > 0) {
      lastItemSku = lastViewEvent[lastViewEvent.length - 1]
      lastItemSku = lastItemSku.ecommerce.items[0].item_id
    }
    if ((lastItemSku == '' || lastItemSku != product?.sku) && pageViewExist) {
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'view_item',
        ecommerce: { currency: product?.currency?.code ? 'USD' : 'USD', items: items },
      })
      return true
    }
    if (lastItemSku == product?.sku) {
      return true
    }
  }
  return false
}

export const productClickEvent = async (product, searchTerm = null) => {
  const items = []
  if (product.variantsData) {
    const brandName = getBrand(product.brand_id)
    let selectedVariant = null
    if (product?.variantId) {
      selectedVariant = product.variantsData.find((item) => item.id == product.variantId)
    } else {
      selectedVariant = product?.variantsData?.length > 0 ? product?.variantsData[0] : null
    }

    const badgeData = await getBadgeData(product, selectedVariant?.sku)

    let productEventData = {
      item_name: product?.name,
      item_id: product?.sku,
      affiliation: '',
      quantity: 1,
      index: product?.index || Number.isInteger(product?.index) ? product?.index : null,
      price: selectedVariant?.calculated_price,
      item_category: product?.custom_fields?.find((field) => field?.name == 'gender')?.value,
      item_category2: product?.custom_fields?.find((field) => field?.name == 'product_type')?.value,
      item_brand: brandName,
      item_variant: selectedVariant?.sku,
      item_list_name: product?.list ? product?.list : null,
      item_list_id: '',
      item_regular_price: selectedVariant?.price,
      item_color: selectedVariant?.option_values?.find(
        (option) => option?.option_display_name == 'Color'
      )?.label,
      item_size: selectedVariant?.option_values?.find(
        (option) => option?.option_display_name == 'Size'
      )?.label,
      item_upc: selectedVariant?.upc,
    }

    if (Object.keys(badgeData)?.length > 0) {
      productEventData = { ...productEventData, ...badgeData }
    }

    if (searchTerm) {
      productEventData['item_search_term'] = searchTerm
    }

    if (Object.keys(badgeData)?.length > 0) {
      productEventData = { ...productEventData, ...badgeData }
    }

    items.push(productEventData)
  }

  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({ ecommerce: null })

    dataLayer.push({
      event: 'select_item',
      ecommerce: { currency: product?.currency?.code ? 'USD' : 'USD', items: items },
    })
  }
}

export const addToWishlistEvent = async (product) => {
  const items = []

  const productData = await getProductById(product?.id)
  const brandName = getBrand(product.brand_id)

  let variant = null
  if (product?.variantId) {
    variant = await getProductVariant(product?.id, product?.variantId)
  }

  const badgeData = await getBadgeData(productData?.data, variant?.data?.sku)

  let productEventData = {
    item_name: productData?.data?.name,
    item_id: productData?.data?.sku,
    affiliation: '',
    quantity: 1,
    index: product?.index || Number.isInteger(product?.index) ? product?.index : null,
    price: variant?.data?.calculated_price,
    item_category: productData?.data?.custom_fields?.find((field) => field?.name == 'gender')
      ?.value,
    item_category2: productData?.data?.custom_fields?.find((field) => field?.name == 'product_type')
      ?.value,
    item_brand: brandName,
    item_variant: variant?.data ? variant?.data?.sku : null,
    item_list_name: product?.list ? product?.list : null,
    item_list_id: '',
    item_regular_price: variant?.data?.price,
    item_color: variant?.data
      ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Color')
        ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Color')
            ?.label
        : ''
      : null,
    item_size: variant?.data
      ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Color')
        ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Size')
            ?.label
        : ''
      : null,
    item_upc: variant?.data ? variant?.data?.upc : null,
  }

  if (Object.keys(badgeData)?.length > 0) {
    productEventData = { ...productEventData, ...badgeData }
  }

  items.push(productEventData)

  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: 'add_to_wishlist',
      ecommerce: { currency: product?.currency?.code ? 'USD' : 'USD', items: items },
    })
  }
}

function areArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}

export const productListViewEvent = async (products, searchTerm = null) => {
  const productArray = Array.isArray(products) ? products : [products]
  const items = []
  let viewListName = ''

  await Promise.all(
    productArray.map(async (product) => {
      const brandName = getBrand(product?.brand_id)
      if (product?.variantsData) {
        let selectedVariant = null
        if (product.variantId) {
          selectedVariant = product.variantsData.find((item) => item.id == product.variantId)
        }

        const badgeData = await getBadgeData(product, selectedVariant?.sku)

        let productEventData = {
          item_name: product?.name,
          item_id: product?.sku,
          affiliation: '',
          quantity: 1,
          index: product?.index || Number.isInteger(product?.index) ? product?.index : null,
          price: selectedVariant?.calculated_price,
          item_category: product?.custom_fields?.find((field) => field?.name == 'gender')?.value,
          item_category2: product?.custom_fields?.find((field) => field?.name == 'product_type')
            ?.value,
          item_brand: brandName,
          item_variant: selectedVariant?.sku,
          item_list_name: product?.list ? product?.list : null,
          item_list_id: '',
          item_regular_price: selectedVariant?.price,
          item_color: selectedVariant?.option_values?.find(
            (option) => option?.option_display_name == 'Color'
          )?.label,
          item_size: selectedVariant?.option_values?.find(
            (option) => option?.option_display_name == 'Size'
          )?.label,
          item_upc: selectedVariant?.upc,
        }

        viewListName = product?.list || ''

        if (Object.keys(badgeData)?.length > 0) {
          productEventData = { ...productEventData, ...badgeData }
        }

        if (searchTerm) {
          productEventData['item_search_term'] = searchTerm
        }

        items.push(productEventData)
      }
    })
  )
  if (viewListName != 'Search Recommendations' && viewListName != 'Navigation') {
    await timeout(3000)
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    const pageViewEvent = dataLayer.filter((item) => item.event == 'page_view')
    const virtualPageView = dataLayer.filter((item) => item.event == 'virtual_page_view')
    const url = new URL(window.location.href)
    const path = url.pathname + url.search
    let current_url = normalizeUrl(process.env.NEXT_PUBLIC_BASE_URL + path)
    let pageViewExist = false

    const lastPageView = pageViewEvent[pageViewEvent.length - 1]
    if (lastPageView && normalizeUrl(lastPageView.page_location) == current_url) {
      pageViewExist = true
    }
    const lastVirtualPageView = virtualPageView[virtualPageView.length - 1]
    if (lastVirtualPageView && normalizeUrl(lastVirtualPageView.page_location) == current_url) {
      pageViewExist = true
    }

    const comingItemNames = items.map((data) => data.item_id)
    const existingListEvent = dataLayer.filter((item) => item.event == 'view_item_list')
    let isNewEvent = true
    for (let i = 0; i < existingListEvent.length; i++) {
      const previousListEventItems = existingListEvent[i].ecommerce.items.map(
        (data) => data.item_id
      )
      const doesListEventExist = areArraysEqual(comingItemNames, previousListEventItems)
      if (doesListEventExist) {
        isNewEvent = false
        break
      }
    }
    if (isNewEvent && items?.length > 0 && pageViewExist) {
      Cookies.set('recentViewListName', viewListName)
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'view_item_list',
        ecommerce: { currency: 'USD', items: items },
      })
      return true
    }
    return false
  }
}

export const formStartEvent = async (form) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'form_start',
      form_destination: form?.form_destination ? form?.form_destination : '',
      form_id: form?.form_id ? form?.form_id : '',
      form_length: form?.form_length ? form?.form_length : '',
      first_field_id: form?.first_field_id ? form?.first_field_id : '',
      first_field_name: form?.first_field_name ? form?.first_field_name : '',
      first_field_position: form?.first_field_position ? form?.first_field_position : '',
      first_field_type: form?.first_field_type ? form?.first_field_type : '',
    })
  }
}

export const formEndEvent = async (form) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'form_submit',
      form_destination: form?.form_destination ? form?.form_destination : '',
      form_id: form?.form_id ? form?.form_id : '',
      form_length: form?.form_length ? form?.form_length : '',
    })
  }
}

export const contentClick = async (url, text) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'content_click',
      link_text: text,
      link_url: url,
      location_id: window.location.href,
    })
  }
}

export const ctaClick = async (url, text) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'cta_click',
      link_text: text,
      link_url: url,
      location_id: window.location.href,
    })
  }
}

export const socialClick = async (url, text) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'social_click',
      link_text: text,
      link_url: url,
      location_id: window.location.href,
    })
  }
}

export const navClick = async (url, text, menu, menu_level) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'nav_click',
      link_text: text,
      link_url: url,
      menu_level: menu_level?.toLowerCase()?.replace(/[-\s]/g, '_'),
      menu: menu?.toLocaleLowerCase(),
    })
  }
}

export const searchCategoryResultClick = async (result) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'search_results_category',
      search_result_category_selection: result,
    })
  }
}

export const errorEvent = async (type, message) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'error',
      error_type: type,
      error_message: message,
    })
  }
}

export const createAccountEvent = async (member) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'account_create',
      location_id: window.location.href,
      member_type: member.type,
      member_subtype: member.subtype,
    })
  }
}

export const logInEvent = async (member) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'account_login',
      location_id: window.location.href,
      member_type: member.type,
      member_subtype: member.subtype,
    })
  }
}

export const newsletterSignupEvent = async (formId) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'newsletter_signup',
      location_id: window.location.href,
      newsletter_location: formId,
    })
  }
}

export const SBStartEvent = async () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'system_builder_start',
      location_id: null,
    })
  }
}

export const SBOverviewEvent = async (tag) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'system_builder_overview',
      system_builder_selection: tag,
    })
  }
}

export const SBHuntEvent = async (tag) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'system_builder_hunt',
      system_builder_selection: tag,
    })
  }
}

export const SBLocationEvent = async (tag) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'system_builder_location',
      system_builder_selection: tag,
    })
  }
}

export const SBWeatherEvent = async (tag) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'system_builder_weather',
      system_builder_selection: tag,
    })
  }
}

export const SBStyleEvent = async (tag) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'system_builder_style',
      system_builder_selection: tag,
    })
  }
}

export const SBStepsEvent = async (tag, step) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'system_builder_' + step,
      system_builder_selection: tag,
    })
  }
}

export const PDPFindbyRetail = async (items, currency, value) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'find_at_retailer',
      item_name: items,
      currency: currency,
      value: value,
    })
  }
}

export const PDPThumbnailZoom = async (items, currency, value) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'pdp_thumbnail_zoom',
      item_name: items,
      currency: currency,
      value: value,
    })
  }
}

export const PDPThumbnailCarousel = async (items, currency, value) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'pdp_thumbnail_carousel',
      item_name: items,
      currency: currency,
      value: value,
    })
  }
}

export const itemFilterEvent = async (name, value) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'item_filter',
      filter_name: name,
      filter_value: value,
    })
  }
}

export const itemSortEvent = async (name) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'item_sort',
      item_sort: name,
    })
  }
}

export const relatedSearchEvent = async (page_type, page_sub_type, link_text, link_url) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'related_search_click',
      page_type: page_type ? page_type : '',
      page_sub_type: page_sub_type ? page_sub_type : '',
      link_text: link_text,
      link_url: link_url,
      location_id: window.location.href,
    })
  }
}

export const videoStartEvent = async (title, url, provider, duration) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'video_start',
      video_title: title ? title : undefined,
      video_url: url ? url : undefined,
      video_provider: provider ? provider : undefined,
      video_duration: duration ? duration : undefined,
      video_current_time: 0,
      video_percent: 0,
      visible: true,
    })
  }
}

export const videoProcessEvent = async (title, url, provider, duration, current_time) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'video_process',
      video_title: title ? title : undefined,
      video_url: url ? url : undefined,
      video_provider: provider ? provider : undefined,
      video_duration: duration ? duration : undefined,
      video_current_time: current_time ? current_time : undefined,
      video_percent: Math.ceil((current_time / duration) * 100),
      visible: true,
    })
  }
}

export const videoEndEvent = async (title, url, provider, duration, current_time) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'video_end',
      video_title: title ? title : undefined,
      video_url: url ? url : undefined,
      video_provider: provider ? provider : undefined,
      video_duration: duration ? duration : undefined,
      video_current_time: current_time ? current_time : undefined,
      video_percent: 100,
      visible: true,
    })
  }
}

export const syncCartItems = async (all_cart_items, customer) => {
  const items = []
  await Promise.all(
    all_cart_items.map(async (item) => {
      const response = await fetch(`${server}/api/searchspring/autocomplete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          q: item?.sku.includes('-') ? item?.sku.split('-')[0] : item?.sku,
          resultsPerPage: 1,
          page: 1,
        }),
      })
      const ssresponse = await response.json()
      let variantCollection = null
      if (ssresponse && ssresponse?.results?.length > 0) {
        variantCollection = JSON.parse(
          ssresponse?.results[0]?.child_sku_options?.replace(/&quot;/g, '"')
        )
      }
      // console.log(item)
      // console.log(variantCollection)
      const productForBadge = await getProductById(item?.product_id)
      // const variant = await getProductVariant(item?.product_id, item?.variant?.id)
      const reviewData = getProductReviewCount(
        item?.sku.includes('-') ? item?.sku.split('-')[0] : item?.sku
      )
      const brandName = getBrand(ssresponse.results[0]['brand_id'])
      const selectedVariant = variantCollection.find((variant) => variant.id == item.variant_id)
      const badgeData = await getBadgeData(productForBadge?.data, selectedVariant?.sku || item?.sku)
      const selectedVariantColor = variantCollection.find(
        (variant) => variant.id == item.variant_id && variant.option == 'Color'
      )
      const selectedVariantSize = variantCollection.find(
        (variant) => variant.id == item.variant_id && variant.option == 'Size'
      )
      let gaCartItem = {
        item_name: item?.name,
        item_id: item?.sku.includes('-') ? item?.sku.split('-')[0] : item?.sku,
        affiliation: '',
        quantity: item?.quantity,
        price: item?.extended_sale_price / (item?.quantity || 1),
        item_category: ssresponse.results[0]['custom_gender'],
        item_category2: ssresponse.results[0]['custom_product_type'],
        item_brand: brandName,
        item_variant: selectedVariant?.child_sku,
        item_list_name: '',
        item_list_id: '',
        item_regular_price: item?.variant?.price,
        item_color: selectedVariantColor?.value,
        item_size: selectedVariantSize?.value,
        item_upc: selectedVariant?.upc,
        item_rating: reviewData?.score || 0,
        item_review_count: reviewData?.total_count || 0,
        coupon: '',
      }

      if (Object.keys(badgeData)?.length > 0) {
        gaCartItem = { ...gaCartItem, ...badgeData }
      }
      items.push(gaCartItem)
    })
  )

  if (items.length > 0) {
    const controller = new AbortController()
    const signal = controller.signal
    const getCartMeta = await getCartMetafields(signal)
    // create/update cart items metafield
    const ifCartItemsExist =
      getCartMeta?.length && getCartMeta.find((item) => item.key == 'cart-items')
    if (ifCartItemsExist) {
      // Check if existing metafield value is different then update
      const newValue = JSON.stringify(items)
      if (ifCartItemsExist.value != newValue) {
        const updateSignal = controller.signal
        let payload = {
          permission_set: 'write_and_sf_access',
          key: 'cart-items',
          value: newValue,
          namespace: 'cart_info',
        }
        updateCartMetafield(payload, ifCartItemsExist.id, updateSignal)
      }
    } else {
      const createSignal = controller.signal
      let payload = {
        permission_set: 'write_and_sf_access',
        key: 'cart-items',
        value: JSON.stringify(items),
        namespace: 'cart_info',
      }
      createCartMetafield(payload, createSignal)
    }

    // create/update customer group metafield
    const ifCustomerGroupExist = getCartMeta.find((item) => item.key == 'customer-group')
    if (ifCustomerGroupExist) {
      // Check if existing metafield value is different then update
      const newValue = customer?.customerGroup?.name || 'undefined'
      if (ifCustomerGroupExist.value != newValue) {
        const updateSignal = controller.signal
        let payload = {
          permission_set: 'write_and_sf_access',
          key: 'customer-group',
          value: newValue,
          namespace: 'cart_info',
        }
        updateCartMetafield(payload, ifCustomerGroupExist.id, updateSignal)
      }
    } else {
      const createSignal = controller.signal
      let payload = {
        permission_set: 'write_and_sf_access',
        key: 'customer-group',
        value: customer?.customerGroup?.name || 'undefined',
        namespace: 'cart_info',
      }
      createCartMetafield(payload, createSignal)
    }

    // create/update pro status metafield
    const ifProStatusExist = getCartMeta.find((item) => item.key == 'pro-status')
    if (ifProStatusExist) {
      // Check if existing metafield value is different then update
      const newValue = customer?.promoOrderUser?.isProAppUser ? 'true' : 'false'
      if (ifProStatusExist.value != newValue) {
        const updateSignal = controller.signal
        let payload = {
          permission_set: 'write_and_sf_access',
          key: 'pro-status',
          value: newValue,
          namespace: 'cart_info',
        }
        updateCartMetafield(payload, ifProStatusExist.id, updateSignal)
      }
    } else {
      const createSignal = controller.signal
      let payload = {
        permission_set: 'write_and_sf_access',
        key: 'pro-status',
        value: customer?.promoOrderUser?.isProAppUser ? 'true' : 'false',
        namespace: 'cart_info',
      }
      createCartMetafield(payload, createSignal)
    }

    // create/update utm params metafield
    const ifUtmParamsExist = getCartMeta.find((item) => item.key == 'utm-parameters')
    if (ifUtmParamsExist) {
      // Check if existing metafield value is different then update
      const newValue =
        window.localStorage.getItem('utmParams') != '' &&
        window.localStorage.getItem('utmParams') != 'undefined'
          ? window.localStorage.getItem('utmParams')
          : 'undefined'
      if (ifUtmParamsExist.value != newValue) {
        const updateSignal = controller.signal
        let payload = {
          permission_set: 'write_and_sf_access',
          key: 'utm-parameters',
          value: newValue,
          namespace: 'cart_info',
        }
        updateCartMetafield(payload, ifUtmParamsExist.id, updateSignal)
      }
    } else {
      const createSignal = controller.signal
      let payload = {
        permission_set: 'write_and_sf_access',
        key: 'utm-parameters',
        value:
          window.localStorage.getItem('utmParams') != '' &&
          window.localStorage.getItem('utmParams') != 'undefined'
            ? window.localStorage.getItem('utmParams')
            : 'undefined',
        namespace: 'cart_info',
      }
      createCartMetafield(payload, createSignal)
    }

    // create/update dnsFlag
    const ifDnsFlagExist = getCartMeta?.find((item) => item?.key == 'dns_flag')
    console.log("ifDnsFlagExist - ", ifDnsFlagExist)
    console.log("customer data - ", customer)
    if (ifDnsFlagExist) {
      let payload = {
        permission_set: 'write_and_sf_access',
        key: 'dns_flag',
        value: customer?.promoOrderUser?.dnsFlag ? 'true' : 'false',
        namespace: 'cart_info',
      }

      updateCartMetafield(payload, ifDnsFlagExist?.id, controller.signal)
    } else {
      let payload = {
        permission_set: 'write_and_sf_access',
        key: 'dns_flag',
        value: customer?.promoOrderUser?.dnsFlag ? 'true' : 'false',
        namespace: 'cart_info',
      }

      createCartMetafield(payload, controller.signal)
    }
  }
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.localStorage.setItem('cartItems', JSON.stringify(items))
    let pixelFrame = document.getElementById('iframe-pixel')

    if (pixelFrame) {
      pixelFrame.contentWindow.postMessage(
        {
          action: 'localVars',
          cartItems: JSON.stringify(items),
        },
        '*'
      )
    }
  }
}

export const viewCartEvent = async (lineItem, cartData) => {
  const lineItems = Array.isArray(lineItem) ? lineItem : [lineItem]
  const items = []
  await Promise.all(
    lineItems.map(async (item) => {
      const product = await getProductById(item?.product_id)
      const variant = await getProductVariant(item?.product_id, item?.variant_id)
      const reviewData = getProductReviewCount(product?.data?.sku || '')
      const brandName = getBrand(product?.brand_id)
      const badgeData = await getBadgeData(product?.data, variant?.data?.sku)

      let productEventData = {
        item_name: product?.data?.name,
        item_id: product?.data?.sku,
        affiliation: '',
        quantity: item?.quantity,
        price: variant?.data?.calculated_price,
        item_category: product?.data?.custom_fields?.find((field) => field?.name == 'gender')
          ?.value,
        item_category2: product?.data?.custom_fields?.find((field) => field?.name == 'product_type')
          ?.value,
        item_brand: brandName,
        item_variant: variant?.data?.sku,
        item_list_name: '',
        item_list_id: '',
        item_regular_price: variant?.data?.price,
        item_color: variant?.data?.option_values?.find(
          (option) => option?.option_display_name == 'Color'
        )
          ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Color')
              ?.label
          : '',
        item_size: variant?.data?.option_values?.find(
          (option) => option?.option_display_name == 'Color'
        )
          ? variant?.data?.option_values?.find((option) => option?.option_display_name == 'Size')
              ?.label
          : '',
        item_upc: variant?.data?.upc,
        item_rating: reviewData?.score || 0,
        item_review_count: reviewData?.total_count || 0,
      }

      if (Object.keys(badgeData)?.length > 0) {
        productEventData = { ...productEventData, ...badgeData }
      }

      items.push(productEventData)
    })
  )
  if (typeof window !== 'undefined' && window.dataLayer) {
    dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: cartData?.currency?.code,
        items: items,
        value: cartData?.cart_amount,
      },
    })
  }
}

export const compareProductsEvent = (products) => {
  try {
    let productNames = []
    let productSkus = []

    products?.forEach((product) => {
      if (product?.name) {
        productNames.push(product?.name)
      }

      if (product?.sku) {
        productSkus.push(product?.sku)
      }
    })

    if (
      typeof window !== 'undefined' &&
      window.dataLayer &&
      productNames?.length > 0 &&
      productSkus?.length > 0
    ) {
      dataLayer.push({
        event: 'product_compare',
        item_compared_name: productNames?.join('|'),
        item_compared_id: productSkus?.join('|'),
      })
    }
  } catch (error) {
    console.error('compareProductsEvent error:', error)
  }
}

export const formatNavClickMenuLevel = (items) => {
  try {
    if (!items || items?.length == 0) {
      return
    }

    let menuLevels = items?.filter((e) => e)

    return menuLevels?.join('::')
  } catch (error) {
    console.log('formatNavClickMenuLevel error', error)
    return ''
  }
}

export const getPreviousListItemName = (productName) => {
  try {
    let listItemName = Cookies.get('recentViewListName')

    if (listItemName && listItemName != '') {
      return listItemName
    }

    const viewListEvents = dataLayer?.filter((item) => item?.event == 'view_item_list')?.reverse()

    if (viewListEvents?.length > 0) {
      for (let event of viewListEvents) {
        let products = event?.ecommerce?.items || []

        let product = products?.find((product) => product?.item_name == productName)

        if (product) {
          listItemName = product?.item_list_name || ''
        }

        break
      }

      return listItemName
    }
  } catch (error) {
    console.log('getPreviousListItemName error', error)
    return ''
  }
}

export const promotionClickEvent = (item) => {
  try {
    if (typeof window !== 'undefined' && window.dataLayer) {
     
      dataLayer.push({
        event: 'select_promotion',
        ecommerce: {
          items: [item],
        },
      })
    }
  } catch (error) {
    console.log('promotionClickEvent error', error)
  }
}

export const viewPromotionEvent = (items) => {
  try {
    if (typeof window !== 'undefined' && window?.dataLayer) {
      const pageViewEvent = dataLayer.filter((item) => item.event == 'page_view')
      const virtualPageView = dataLayer.filter((item) => item.event == 'virtual_page_view')
      const url = new URL(window.location.href)
      const path = url.pathname
      let current_url = normalizeUrl(process.env.NEXT_PUBLIC_BASE_URL + path)
      let pageViewExist = false

      const lastPageView = pageViewEvent[pageViewEvent.length - 1]
      if (lastPageView && normalizeUrl(lastPageView.page_location) == current_url) {
        pageViewExist = true
      }
      const lastVirtualPageView = virtualPageView[virtualPageView.length - 1]
      if (lastVirtualPageView && normalizeUrl(lastVirtualPageView.page_location) == current_url) {
        pageViewExist = true
      }
      const existingPromoEvents = dataLayer?.filter((item) => item?.event == 'view_promotion')
      const isNewEvent = true

      for (let i = 0; i < existingPromoEvents?.length; i++) {
        const previousEvents = existingPromoEvents[i]?.ecommerce?.items
        const doesListEventExist = areArraysEqual(items, previousEvents)

        if (doesListEventExist) {
          isNewEvent = false
          break
        }
      }

      if (pageViewExist && isNewEvent) {
        dataLayer.push({ ecommerce: null })
        dataLayer.push({
          event: 'view_promotion',
          ecommerce: { items: items },
        })
        return true
      }
      if (!isNewEvent) {
        return true
      }
      return false
    }
  } catch (error) {
    console.log('viewPromotionEvent', error)
  }
}

export const prepareViewListItems = async (productList, listName) => {
  try {
    const gaProducts = []

    const skus = productList
      ?.map((product) => {
        let sku = product?.productId?.split('-')

        return sku?.[0]
      })
      ?.filter((e) => e)

    if (skus?.length > 0) {
      const params = {
        'sku:in': skus.join(','),
        include_fields:
          'variants,primary_image,custom_url,name,price,sale_price,custom_fields,sku,brand_id',
        include: 'variants,custom_fields',
        is_visible: true,
      }

      const { data: productData } = await getProductsBySkus(params)

      if (productData?.length > 0) {
        for (let [index, product] of productList?.entries()) {
          const skuPartial = product?.productId?.split('-')
          const newProduct = productData?.find((product) => product?.sku == skuPartial?.[0])
          const variantSku = skuPartial?.[1] ? product?.productId : newProduct?.variants?.[0]?.sku
          const variantData = newProduct?.variants?.find((variant) => variant?.sku == variantSku)

          if (variantData && newProduct) {
            let colorOpt = variantData.option_values
              .find((item) => item.option_display_name == 'Color')
              ?.label.toLowerCase()
              .replaceAll(' ', '-')
              .replaceAll('/', '-')
            const gaProduct = {
              custom_url: {
                url: newProduct.custom_url.url + colorOpt,
              },
              primary_image: {
                url_thumbnail: variantData?.image_url,
              },
              name: newProduct?.name,
              price: variantData?.price,
              calculated_price: variantData?.calculated_price,
              custom_fields: newProduct?.custom_fields,
              review: {
                score: getProductReviewCount(newProduct.sku) || 0,
              },
              variantId: variantData?.id,
              brand_id: newProduct?.brand_id,
              variantsData: newProduct?.variants,
              sku: newProduct?.sku,
              index: index,
              list: listName,
              sale_price: variantData?.sale_price,
            }

            gaProducts.push(gaProduct)
          }
        }
      }
    }

    return gaProducts
  } catch (error) {
    console.log('prepareViewListItems error', error)
  }
}

export const viewContentPromotionEvent = (items) => {
  try {
    if (typeof window !== 'undefined' && window?.dataLayer) {
      const pageViewEvent = dataLayer.filter((item) => item.event == 'page_view')
      const virtualPageView = dataLayer.filter((item) => item.event == 'virtual_page_view')
      const url = new URL(window.location.href)
      const path = url.pathname+url.search
      let current_url = normalizeUrl(process.env.NEXT_PUBLIC_BASE_URL + path)
      let pageViewExist = false
      const lastPageView = pageViewEvent[pageViewEvent.length - 1]
      if (lastPageView && normalizeUrl(lastPageView.page_location) == current_url) {
        pageViewExist = true
      }
      const lastVirtualPageView = virtualPageView[virtualPageView.length - 1]
      if (lastVirtualPageView && normalizeUrl(lastVirtualPageView.page_location) == current_url) {
        pageViewExist = true
      }
      // const existingPromoEvents = dataLayer?.filter((item) => item?.event == 'view_promotion')
      // const isNewEvent = true
      let allowCurrentEvent = checkForExistingPromotionEvent(items)
      // for (let i = 0; i < existingPromoEvents?.length; i++) {
      //   const previousEvents = existingPromoEvents[i]?.ecommerce?.items
      //   const doesListEventExist = JSON.stringify(items) == JSON.stringify(previousEvents)

      //   if (doesListEventExist) {
      //     isNewEvent = false
      //     break
      //   }
      // }
      
      if (pageViewExist && allowCurrentEvent) {
        dataLayer.push({ ecommerce: null })
        dataLayer.push({
          event: 'view_promotion',
          ecommerce: { items: items },
        })
        return true
      }
      if (!allowCurrentEvent) {
        return true
      }
      return false
    }
  } catch (error) {
    console.log('viewContentPromotionEvent', error)
  }
}

export const selectContentPromotionEvent = (items) => {
  try {
    if (typeof window !== 'undefined' && window?.dataLayer) {
        dataLayer.push({ ecommerce: null })
        dataLayer.push({
          event: 'select_promotion',
          ecommerce: { items: items },
        })
      // const existingPromoEvents = dataLayer?.filter((item) => item?.event == 'select_promotion')
      // const isNewEvent = true
      
      // for (let i = 0; i < existingPromoEvents?.length; i++) {
      //   const previousEvents = existingPromoEvents[i]?.ecommerce?.items
      //   const doesListEventExist = JSON.stringify(items) == JSON.stringify(previousEvents)

      //   if (doesListEventExist) {
      //     isNewEvent = false
      //     break
      //   }
      // }
      
      // if (isNewEvent) {
      //   dataLayer.push({ ecommerce: null })
      //   dataLayer.push({
      //     event: 'select_promotion',
      //     ecommerce: { items: items },
      //   })
      // }
    }
  } catch (error) {
    console.log('selectContentPromotionEvent', error)
  }
}

const checkForExistingPromotionEvent = (items) => {
  let allPromotionEvents = dataLayer.filter(event => event.event == 'view_promotion');
  let allowEvent = true;
  if(allPromotionEvents.length > 0) {
    allPromotionEvents = allPromotionEvents.reverse();
    let lastPromotionEvent = allPromotionEvents[0]?.ecommerce?.items[0]?.promotion_id || '';
    let currentPromotionEvent = items[0]?.promotion_id || '';
    // console.log('last promotion id - ', lastPromotionEvent)
    // console.log('current promotion id - ', currentPromotionEvent)
    let currentSlot = items[0]?.creative_slot || '';
    let currentLocationId = items[0]?.location_id || '';
    // console.log('current creative slot - ', currentSlot)
    if(lastPromotionEvent != currentPromotionEvent) {
      // console.log("New Page Detected. Allow this event.")
      allowEvent = true;
    } else {
      for(let i=0; i<allPromotionEvents.length; i++) {
        let lastViewEvent = allPromotionEvents[i]?.ecommerce?.items[0]?.promotion_id || '';
        let lastSlot = allPromotionEvents[i]?.ecommerce?.items[0]?.creative_slot || '';
        let locationId = allPromotionEvents[i]?.ecommerce?.items[0]?.location_id || '';
        // console.log('last view id - ', lastViewEvent);
        // console.log('current view id - ', currentPromotionEvent);
        // console.log('last slot id - ', lastSlot);
        // console.log('current slot id - ', currentSlot);
        if(lastViewEvent == currentPromotionEvent && currentSlot == lastSlot && currentLocationId == locationId) {
          // console.log("Same page event. DO NOT TRIGGER.");
          allowEvent = false;
          break;
        } else if(lastViewEvent != currentPromotionEvent) {
          // console.log("Viewed Page Detected. Allow this event.");
          allowEvent = true;
          break;
        } else {
          // console.log("Check the next event.")
          continue;
        }
      }
    }
  }
  return allowEvent;
}